.mydevices-page {
    .card-list {
        .card+.card {
            margin-left: 12px;
        }
    }

    .card {
        flex: 1;
        margin-top: 16px;
        padding: 15px 0;
        border-radius: 10px;
        background: var(--white-color);
        border: 1px solid #ECF0F9;

        .card-info {
            flex: 1;
            padding-inline: 16px;

            .title {
                line-height: 1;
                font-size: 12px;
            }

            .device-status {
                margin-top: 15px;
                line-height: 1;
                font-size: 12px;

                >:nth-child(2) {
                    margin-inline: 16px;
                }

                .point {
                    width: 12px;
                    height: 12px;
                    border-radius: 12px;

                    &.online {
                        background: var(--green-color);
                    }

                    &.offline {
                        background: var(--grey-color);
                    }
                }
            }

            .value {
                margin-top: 10px;
                font-size: 20px;
                line-height: 24px;

                >:first-child {
                    font-weight: 700;
                }

                >:last-child {
                    font-size: 14px;
                }
            }

            .detail {
                margin-top: 10px;
                font-size: 14px;
                color: var(--theme-color);
                cursor: pointer;
            }
        }

        .card-info+.card-info {
            border-left: 1px solid #ECF0F9;
        }
    }
}