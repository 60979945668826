.referral-page {
    .card {
        margin-top: 16px;
        margin-bottom: 16px;
        padding: 15px 0;
        border-radius: 10px;
        background: var(--white-color);
        border: 1px solid #ECF0F9;

        >.title {
            text-align: center;
            font-weight: bold;
            font-size: 24px;
        }

        .text {
            padding: 8px 16px;
            font-size: 14px;
            line-height: 1.5;
        }

        .link {
            font-weight: 400;
            margin: 0;
            font-size: 14px;
            color: var(--theme-color);
            cursor: pointer;
            padding-inline: 6px;
        }

        .card-info {
            flex: 1;
            padding-inline: 12px;
            margin-bottom: 12px;

            .title {
                line-height: 1;
                font-size: 12px;
            }

            .value {
                margin-top: 10px;
                font-size: 20px;
                line-height: 24px;

                >:first-child {
                    font-weight: 700;
                }
            }
        }
    }
}

.invite-code {
    input {
        text-align: center;
    }
}