.header {
    width: 100%;

    button {
        font-size: 14px;
        padding: 8px 20px;
        border-radius: 23px;
    }

    .social-list {
        margin-right: 0;
    }

    .menu {
        padding-block: 8px;
        padding-inline: 10px;
        // margin-top: px;
        display: flex;
        justify-content: space-between;

        .nav {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: var(--black-color);
            cursor: pointer;
        }

        .link {
            font-weight: 400;
            margin: 0;
            font-size: 14px;
            color: var(--black-color);
            cursor: pointer;
            padding-inline: 6px;
            outline-offset: 0 !important;
            outline: none !important;

            &.active {
                font-weight: 700;
                border-bottom: 1px solid var(--theme-color);
                color: var(--theme-color);
                position: relative;

            }
        }
    }

    .bg-container {
        width: 100%;
        height: 140px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .container {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 8px;
        font-size: 30px;
        color: var(--white-color);
        padding-inline: 10px;

        .userinfo {
            font-size: 14px;
            line-height: 16px;

            .account {
                margin-top: 4px;
                margin-bottom: 8px;
                padding: 4px 8px 4px 26px;
                border-radius: 33px;
                background: var(--theme-color);
                position: relative;

                .avatar {
                    position: absolute;
                    left: -1px;
                    top: -1px;
                    width: 26px;
                    height: auto;
                }
            }
        }
    }

    .esitmate-credit {
        padding-left: 10px;
        color: var(--white-color);
        font-size: 14px;
        line-height: 16px;

        .value {
            font-size: 30px;
            line-height: 36px;
            font-weight: 700;

            .unit {
                margin-left: 1em;
                color: var(--white-color);
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
            }
        }
    }

    .referral-link {
        position: absolute;
        right: 8px;
        bottom: 8px;
    }

}