.dashboard-page {
    .card {
        margin-top: 16px;
        padding: 15px 20px;
        border-radius: 10px;
        background: var(--white-color);
        border: 1px solid #ECF0F9;

        .title {
            font-size: 14px;
            line-height: 17px;

            .apy {
                color: var(--grey-color);
                margin-right: 8px;
            }
        }

        .value {
            line-height: 28px;
            margin-top: 14px;

            >:first-child {
                font-size: 24px;
                font-weight: 700;
            }
        }
    }

}