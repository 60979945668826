.borrow-page {

    .desc-wrap {
        padding-left: 10px;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-80%);

        .desc {
            padding-inline: 20px;
            font-size: 16px;
            font-weight: 700;
        }

        .desc+.desc {
            margin-top: 20px;
        }
    }


    .card {
        margin-top: 16px;
        padding: 15px 0;
        border-radius: 10px;
        background: var(--white-color);
        border: 1px solid #ECF0F9;

        .unit {
            font-size: 14px;
            margin-left: 1em;
        }

        .card-info {
            flex: 1;
            padding-inline: 12px;
            margin-bottom: 12px;

            .title {
                line-height: 1;
                font-size: 12px;
            }

            .value {
                margin-top: 10px;
                font-size: 20px;
                line-height: 24px;

                >:first-child {
                    font-weight: 700;
                }


            }
        }

        .card-info+.card-info {
            border-left: 1px solid #ECF0F9;
        }
    }


}