// modify mui style
.MuiOutlinedInput-notchedOutline {
  border-color: #DCDFE6;
}

input {
  background-color: transparent;
  border-radius: 4px;
}


.MuiPaper-root {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  .title {
    margin-block: 16px;
    padding-inline: 16px;
    font-size: 30px;
    color: var(--black-color);
    font-weight: 700;
    line-height: 35px;
  }

  .apy {
    font-size: 18px;
    padding-inline: 16px;

    >:first-child {
      color: var(--black-color);
    }

    >:last-child {
      color: var(--theme-color);
    }
  }

  .input-container {
    padding-inline: 16px;
    margin-top: 16px;

    .invitation-code {
      font-size: 30px;
      font-weight: 500;
      text-align: center;
    }
  }

  .display-amount {
    margin-inline: 16px;
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--theme-color);
    height: 43px;

    .left {
      font-size: 14px;
      line-height: 16px;

      >:first-child {
        color: #000000;
      }

      >:last-child {
        color: var(--grey-color)
      }
    }

    .right {
      font-size: 18px;
      color: var(--theme-color);
    }
  }

  .balance {
    margin-top: 16px;
    padding-inline: 16px;
    font-size: 14px;
    color: var(--black-color);
  }

  .action-group {
    padding-bottom: 32px;
    margin-bottom: env(safe-area-inset-bottom);
    margin-bottom: constant(safe-area-inset-bottom);
    margin-top: 32px;
    padding-inline: 16px;

    button+button {
      margin-top: 18px;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
  padding: 12px !important;
  border-radius: 10px !important;
  border: 1px solid #96A9D7 !important;
  background: #0E4E6E !important;
  backdrop-filter: blur(10px);
}