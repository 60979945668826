.cus-input {
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--theme-color);

    input {
        padding: 0;
        padding-right: 70px;
        background-color: transparent !important;
        color: var(--black-color);
        font-size: 20px;
        line-height: 43px;
        height: 43px;
        width: 100%;
        text-align: right;
    }

    input::placeholder {
        color: var(--grey-color);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

    // display: flex;
    // border-width: 1px;
    // border-style: solid;
    // border-image: linear-gradient(90deg, #25dd5b 0%, #4cb1f0 50%) 1;
    .prefix {
        position: absolute;
        left: 15px;
        top: calc(22px + 0.4em);
        transform: translate(0, -50%);
        z-index: 1;
        width: 18px;
    }

    .MuiInputLabel-root {
        margin-left: 34px;
        color: #999999 !important;
        font-size: 16px !important;
        // transform: scale(1) !important;

        &.Mui-error {
            color: #999999;
        }

        &.Mui-focused {
            color: var(--theme-color) !important;

            &.Mui-error {
                color: #EF2D2D !important;
            }
        }

        //     &.Mui-focused {
        //     margin-left: 20px;
        // }
    }


    .MuiInputBase-root {
        background-color: transparent !important;
        border-radius: 10px !important;
        outline: none;

        fieldset {
            outline: none;
            border: none;
        }

        // &:hover{
        //     borde
        // }
        // &.Mui-focused {
        //     // border-radius: 8px;
        //     // border-width: 1px;
        //     // border-style: solid;
        //     // border-image: linear-gradient(90deg, #25dd5b 0%, #4cb1f0 50%) 1;
        //     border: double 1px transparent;
        //     border-radius: 8px;
        //     background-image: linear-gradient(180deg, #01D9DA -4.02%, #0156E2 116.13%);
        //     background-origin: border-box;
        //     background-clip: content-box,
        //         border-box;
        // }

        &.Mui-error {
            border: 1px solid #EF2D2D;
            border-radius: 8px;
        }
    }

    .MuiFormHelperText-root {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .MuiFilledInput-root:before {
        display: none;
        // content: "" !important;
    }

    .MuiFilledInput-root:after {
        display: none;
        // content: "" !important;
    }

    .MuiFormHelperText-root.Mui-error {
        font-size: 14px;
        line-height: 1.6;
        color: #EF2D2D;
    }

    .suffix {
        background: #E1E7FF;
        cursor: pointer;
        text-align: center;
        position: absolute;
        color: var(--theme-color);
        right: 12px;
        top: 50%;
        padding: 0 8px;
        font-size: 14px;
        letter-spacing: 0;
        border-radius: 5px;
        // transform: t;
        transform: translateY(-50%);
    }
}