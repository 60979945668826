.loader-mask {
    background-color: #FFFFFF;
    --loader-color: #EBEBF2;
    width: 100%;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    opacity: 1;
    background-size: 300% 100%;
    background-position: 100% 0;
    background-image: linear-gradient(90deg, var(--loader-color) 0, var(--loader-color) 33.33%, transparent 44.44%, transparent 55.55%, var(--loader-color) 66.66%, var(--loader-color) 100%);

    &.loading {
        animation: mask 2s infinite;
    -webkit-animation: mask 2s infinite; }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--loader-color);
        opacity: 0.2;
    }

}

@keyframes mask {
    100% {
        background-position: 0 0;
    }
}