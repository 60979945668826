.device-list-page {
    .card {
        position: relative;
        flex: 1;
        margin-top: 16px;
        padding: 15px;
        border-radius: 10px;
        background: var(--white-color);
        border: 1px solid #ECF0F9;
        font-size: 18px;
        color: var(--black-color);

        .device-status {
            font-size: 14px;
        }

        .point {
            width: 12px;
            height: 12px;
            border-radius: 12px;
            margin-right: 4px;

            &.online {
                background: var(--green-color);
            }

            &.offline {
                background: var(--grey-color);
            }
        }

        >:last-child {
            font-size: 14px;
            padding-left: 8px;

            // justify-content: flex-end;
            .day-reward {
                color: var(--grey-color);

                .unit {
                    font-size: 14px;
                    color: var(--grey-color);
                }
            }

        }
    }
}