* {
  margin: 0;
}

:root {
  font-family: Arial, 'Roboto';
  line-height: 1.6;
  font-weight: 400;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  --white-color: #FFFFFF;
  --theme-color: #2A57F6;
  --grey-color: #9AA8CB;
  --black-color: #141316;
  --green-color: #12FF54;
  --error-color: #FE2618;
  // color: var(--black-color);
}



body {
  background: #F5F6FC;
  height: 100vh;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

.page-container {
  position: relative;
  // padding: 0 100px;
  box-sizing: border-box;
  // width: 74vw;
  min-width: 1100px;
  max-width: 1440px;
  height: 100vh;
  margin: 0 auto;
  flex: 1;
  overflow-y: scroll;
}



table {
  border-collapse: collapse;
}

table,
th,
td {
  border: 1px solid black;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}


.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-bold {
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

[aria-labelledby="rk_connect_title"] {
  button {
    justify-content: flex-start;
    height: auto;
  }
}

button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  outline: none;
  border: none;
  border-radius: 10px;
  padding-inline: 32px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  /* background-color: #1a1a1a; */
  cursor: pointer;
  transition: border-color 0.25s;

  &.full {
    width: 100%;
  }

  &:hover {
    outline: none;
  }
}

.bg-primary {
  background: var(--theme-color);
}

.bg-secondary {
  background: #2A57F614;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.bg-white {
  padding: 4px 15px;
  border-radius: 5px;
  background: var(--white-color);
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.bg-transparent {
  background: transparent;
  color: var(--theme-color);
  border: 1px solid var(--theme-color);
}

.bg-primary-error {
  background-image: linear-gradient(-45deg, #F61400 0%, #DE8400 100%);
}

.relative {
  position: relative;
}

.unit {
  color: var(--black-color);
  margin-left: 0.2em;
}

button[disabled] {
  cursor: not-allowed;
  color: var(--white-color);
  border-radius: 10px;
  background: var(--grey-color);
  backdrop-filter: blur(10px);
  border: none;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

.btn-group {
  position: absolute;
  z-index: 1;
  margin-bottom: constant(safe-area-inset-bottom);
  margin-bottom: env(safe-area-inset-bottom);
  bottom: 12px;
  left: 0;
  right: 0;
  padding-inline: 10px;

  button {
    height: 45px;
  }

  button+button {
    margin-top: 12px;
  }
}

.devices-status-tab-list {
  margin-top: 12px;

  .status-tab {
    background: #2A57F626;
    color: var(--theme-color);
    padding: 6px 12px;
    border-radius: 23px;
    font-size: 14px;
    line-height: 16px;

    &.disabled {
      color: var(--white-color);
      background: var(--grey-color);
    }

    &.active {
      background: var(--theme-color);
      color: var(--white-color);
    }
  }

  .status-tab+.status-tab {
    margin-left: 12px;
  }
}

@media (max-width:600px) {
  .page-container {
    width: 100%;
    min-width: auto;
    max-width: none;
    padding-left: 10px;
    padding-right: 10px;
  }
}