.device-detail-page {
    .statistics-item-list {
        margin-top: 16px;

        .statistics-item {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            padding: 12px 16px;
            background: rgba(39, 91, 204, .05);
            border-radius: 8px;
            height: 78px;

            .title {
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                color: #6d7f99;
            }

            .amount {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: #002d70;
            }

            .point {
                width: 12px;
                height: 12px;
                border-radius: 12px;
                margin-right: 8px;

                &.Online {
                    background: var(--green-color);
                }

                &.Offline {
                    background: var(--grey-color);
                }
            }
        }

        .statistics-item+.statistics-item {
            margin-left: 16px;
        }
    }


    .available {
        margin-top: 16px;
        padding: 12px 16px;
        border-radius: 8px;
        background-color: #4b84ff;

        .title {
            color: var(--white-color);
            font-size: 14px;
            line-height: 22px;
            font-weight: bold;
        }

        .list-item {
            font-weight: 600;
            color: var(--white-color);
            padding: 12px 0;
            font-size: 16px;
            line-height: 24px;

            span {
                margin-left: 8px;
            }
        }

        .amount {
            font-weight: 700;
        }
    }

    .total-collateral {
        margin-top: 16px;
        padding: 12px 16px;
        border-radius: 8px;
        background-color: rgba(39, 91, 204, .05);

        .title {
            color: #6d7f99;
            font-size: 14px;
            line-height: 22px;
            font-weight: bold;
        }

        .list-item {
            font-weight: 600;
            color: #6d7f99;
            padding: 12px 0;
            font-size: 16px;
            line-height: 24px;

            span {
                margin-left: 8px;
            }
        }

        .amount {
            font-weight: 700;
        }
    }

    .activity-list {
        margin-top: 16px;
        padding: 0 16px;
        overflow-y: scroll;

        .item {
            padding: 12px 0;
            height: 72px;
            box-shadow: inset 0 -1px #f0f0f0;
            counter-reset: #002d70;
            box-sizing: border-box;

            .left {
                flex-direction: column;
                justify-content: space-between;

                :first-child {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                }

                :last-child {
                    margin-top: 4px;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 1px;
                    color: #6d7f99;
                }
            }

            .right {
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                text-align: right;
                color: #002d70;
            }
        }
    }
}

.confirm-dialog {
    .MuiPaper-root {
        border-radius: 20px;
        padding: 24px 16px;
    }

    .confirm-title {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
        color: #002d70;
        text-align: center;
    }

    .content1 {
        margin-top: 24px;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: #002d70;
    }

    .content2 {
        margin-top: 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #6d7f99;
    }

    button {
        width: 160px;
        margin: 24px auto 0 auto;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, .12);
        box-shadow: 0 2px #0000000b;
    }
}