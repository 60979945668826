.device-transfer-page {
    margin-bottom: 150px;

    .card {
        position: relative;
        margin-top: 16px;
        padding: 15px;
        border-radius: 10px;
        background: var(--white-color);
        border: 1px solid #ECF0F9;
        font-size: 18px;
        color: var(--black-color);

        >:nth-child(2) {
            font-size: 14px;
            line-height: 16px;

            .unit {
                color: var(--theme-color);
            }
        }

        .checkbox {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .btn-group {
        .error-text {
            padding-inline: 30px;
            font-size: 18px;
            color: var(--error-color);
            text-align: center;

            .unit {
                color: var(--error-color);
            }
        }
    }
}