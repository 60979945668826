.loading-dialog {
    .dialog-title {
        text-align: center;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
    }

    .confirm-tx {
        text-align: center;
    }
}